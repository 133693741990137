html {
  -webkit-font-smoothing: antialiased;
}

.darkInput input:-webkit-autofill,
.darkInput input:-webkit-autofill:hover {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 0 0 0px 1000px #121212 inset;
}

.lightInput input:-webkit-autofill,
.lightInput input:-webkit-autofill:hover {
  -webkit-text-fill-color: #121212 ;
  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.7) inset;
}