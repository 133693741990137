body { margin: 0; padding: 0; }

.warning-cell {
    background-color: rgba(255, 202, 0, 0.4);
}

.label-cell {
    background-color: rgba(0, 255, 133, 0.2)
}

.disabled-table {
    background-color: rgba(255, 0, 0, 0.2);
    width: 100%;
    height: 100%
}